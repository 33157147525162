<template>
  <th-page-wrapper>
    <th-tab-page-wrapper :headers="headers">
      <router-view :key="$route.fullPath" />
    </th-tab-page-wrapper>
  </th-page-wrapper>
</template>

<script>
import { getNavigation } from './navigation'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.utilities.cms.title')
    }
  },
  setup() {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)
    return { featureConfig }
  },
  computed: {
    headers() {
      const headers = getNavigation({ featureConfig: this.featureConfig })
      return headers
        .filter((item) => {
          return (
            item.enabled === true &&
            this.$checkPermissions({ scopes: item.scopes })
          )
        })
        .map((item) => {
          return {
            label: this.$t(item.key),
            key: item.key,
            value: item.value
          }
        })
    }
  }
}
</script>

<style scoped></style>
